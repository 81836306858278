import { AxiosResponse } from 'axios'
import I18n from 'i18next'
import Logger from 'logger'
import { DateTime } from 'luxon'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { Database } from 'mobx-document'
import socket, { SendResponse } from 'socket.io-react'
import gcAPI, { initAPI } from '~/apis/gc'
import config from '~/config'
import { SubmitResult } from '~/ui/form'
import appStore from './appStore'
import { AuthResetAccountEndpoint, OAuthTokenSet } from './auth'
import AuthResetAccountDocument from './auth/AuthResetAccountDocument'
import { PreviewStore } from './previewStore'
import socketStore from './socketStore'
import { register, submitResultForAxiosResponse } from './support'

export class AuthenticationStore {

  constructor() {
    makeAutoObservable(this)
  }

  public authToken: string | null = null

  public loginStatus: LoginStatus = 'logged-out'

  public get isLoggedIn() {
    if (this.loginStatus === 'logged-in') { return true }
    if (PreviewStore.previewing) { return true }

    return false
  }

  public loginCompleted: boolean = false

  //------
  // Log in / signup

  public async logIn(data: LoginData): Promise<SubmitResult> {
    await this.logOut()
    runInAction(() => {
      this.loginStatus = 'logging-in'
    })

    const language = I18n.language
    const promise  = gcAPI().post('auth', {...data, language})
    return await promise.then(res => this.onLogInComplete(res, 'token' in data))
  }

  @action
  private onLogInComplete = (response: AxiosResponse, usedToken: boolean): SubmitResult => {
    this.authToken = null
    this.loginStatus = 'logged-out'

    if (response.status === 401) {
      if (usedToken) {
        return {
          status: 'error',
          error: new InvalidToken(),
        }
      } else {
        return {
          status: 'invalid',
          errors: [{field: 'password', message: I18n.t('login:invalid')}],
        }
      }
    } else if (response.status === 410) {
      return {
        status: 'error',
        error:  new ProjectArchived(),
      }
    } else {
      this.authToken   = response.data.authToken
      this.loginStatus = 'logged-in'

      this.onAuthenticated()
      return submitResultForAxiosResponse(response)
    }
  }


  //------
  // PIN reset

  private pinResetAccounts = new Database<AuthResetAccountDocument>({
    getDocument:   account => new AuthResetAccountDocument(account.participantID, {initialData: account}),
    emptyDocument: id => new AuthResetAccountDocument(id),
    getID:         account => account.participantID,
  })

  public async requestAuthReset(email: string) {
    const response = await gcAPI().post('auth-reset/request', {email, source: 'web'})
    return submitResultForAxiosResponse(response)
  }

  public pinResetAccountEndpoint(token: string) {
    return new AuthResetAccountEndpoint(this.pinResetAccounts, {token})
  }

  public async resetAuth(token: string, account: string, pin: string) {
    const response = await gcAPI().post('auth-reset', {token, account, pin})
    return submitResultForAxiosResponse(response)
  }

  //------
  // Direct authentication (oAuth / previewing)

  public async authenticateWithToken(token: string) {
    await this.logOut()

    this.authToken   = token
    this.loginStatus = 'logged-in'
    this.onAuthenticated()
  }

  //------
  // Onboarding

  public onboarded: boolean = false

  public onboard() {
    if (this.onboarded) {
      this.onOnboardComplete({ok: true, body: undefined})
    } else {
      socket
        .send('profile:onboard')
        .then(this.onOnboardComplete)
    }
  }

  private onOnboardComplete = (response: SendResponse<void>) => {
    if (!response.ok) {
      Logger.error('Authentication', `Onboard failed: ${response.error.message}`, response.error)
    }

    this.loginCompleted = true
    this.onboarded = response.ok
  }

  //------
  // Log out

  public async logOut(options: LogOutOptions = {}) {
    const {endOAuthSession = false} = options

    if (this.loginStatus === 'logged-out') { return }

    this.authToken = null
    this.onboarded = false
    this.loginCompleted = false

    this.clearOAuthRefreshTokenTimer()
    this.emit('logout')
    socketStore.disconnect()

    if (endOAuthSession) {
      this.endOAuthSession()
    } else {
      this.oAuthTokens.clear()
    }
  }

  //------
  // OAuth

  private readonly oAuthTokens: Map<string, OAuthTokenSet>             = new Map()
  private oAuthRefreshTokenTimer: ReturnType<typeof setTimeout> | null = null

  public storeOAuthTokens(provider: string, tokens: OAuthTokenSet) {
    const existing = this.oAuthTokens.get(provider)
    if (tokens.id_token == null && existing?.id_token != null) {
      tokens.id_token = existing.id_token
    }
    this.oAuthTokens.set(provider, tokens)
    this.setOAuthRefreshTokenTimer(provider, tokens.expires_at)
  }

  private setOAuthRefreshTokenTimer(provider: string, expiresAt: number) {
    if (expiresAt == null) { return }

    const now       = DateTime.now()
    const refreshIn = expiresAt - now.toSeconds() - 30 // Refresh 30 seconds early, just to be sure

    if (refreshIn <= 0) {
      this.refreshOAuthToken(provider)
    } else {
      this.oAuthRefreshTokenTimer = setTimeout(
        () => this.refreshOAuthToken(provider),
        refreshIn * 1000,
      )
    }
  }

  private clearOAuthRefreshTokenTimer() {
    if (this.oAuthRefreshTokenTimer == null) { return }
    clearTimeout(this.oAuthRefreshTokenTimer)
  }

  public getOAuthTokens(provider: string): OAuthTokenSet | null {
    return this.oAuthTokens.get(provider) ?? null
  }

  public refreshingOAuthToken: boolean = false

  public async refreshOAuthToken(provider: string) {
    if (this.refreshingOAuthToken) { return }

    const tokens = this.getOAuthTokens(provider)
    if (tokens == null) { return }

    this.refreshingOAuthToken = true
    const response = await gcAPI().post(`oauth/${provider}/refresh-token`, {
      refreshToken: tokens.refresh_token,
    })

    if (response.status === 200) {
      this.storeOAuthTokens(provider, response.data)
      this.emit('oauth:token-refreshed')
    }

    runInAction(() => {
      this.refreshingOAuthToken = false
    })
  }

  private endOAuthSession() {
    const singleOAuthProvider = this.oAuthTokens.size === 1 ? Array.from(this.oAuthTokens.entries())[0] : null
    if (singleOAuthProvider == null) {
      this.loginStatus = 'logged-out'
      return
    }

    const [provider, tokens] = singleOAuthProvider
    const url = new URL(`${config.urls.api}/v5/oauth/${provider}/end-session/init`)
    url.searchParams.set('idTokenHint', tokens.id_token)
    url.searchParams.set('platform', 'web')
    if (appStore.app != null) {
      url.searchParams.set('app', appStore.app.id)
    }
    this.oAuthTokens.clear()

    document.location.replace(url)
  }

  //------
  // Socket

  private async onAuthenticated() {
    if (this.authToken != null) {
      socketStore.connect(this.authToken)
      this.emit('login')
      initAPI(this.authToken)
    }
    this.onboard()
  }

  public onActivate() {
    if (this.authToken == null) { return }
    if (socketStore.socketStatus === 'disconnected') {
      socketStore.connect(this.authToken)
    }
  }

  //-----
  // Events

  private readonly listeners = {
    'login':  new Set<() => void>(),
    'logout': new Set<() => void>(),
    'oauth:token-refreshed': new Set<() => void>(),
  }

  public on(event: 'login' | 'logout' | 'oauth:token-refreshed', listener: () => void) {
    const set = this.listeners[event]
    set.add(listener)
    return () => set.delete(listener)
  }

  private emit(event: 'login' | 'logout' | 'oauth:token-refreshed') {
    const set = this.listeners[event]
    set.forEach(it => it())
  }


  //------
  // Persistence

  public persistenceKey = 'auth'

  public get persistedState(): PersistedState {
    return {
      authToken:      this.authToken,
      oAuthTokens:    Array.from(this.oAuthTokens.entries()),
      onboarded:      this.onboarded,
      loginCompleted: this.loginCompleted,
    }
  }

  public rehydrate(state: PersistedState) {
    this.authToken      = state.authToken
    this.onboarded      = state.onboarded
    this.loginCompleted = state.loginCompleted
    this.loginStatus    = this.authToken == null ? 'logged-out' : 'logged-in'

    for (const [provider, tokens] of state.oAuthTokens ?? []) {
      this.storeOAuthTokens(provider, tokens)
    }
  }

  public init() {
    if (this.authToken != null) {
      this.onAuthenticated()
    }
  }

}

interface PersistedState {
  authToken:      string | null
  oAuthTokens:    Array<[string, OAuthTokenSet]>
  onboarded:      boolean
  loginCompleted: boolean
}

export type LoginData =
  | {email: string, pin: string}
  | {token: string}

export type LoginStatus = 'logged-out' | 'logging-in' | 'logged-in'

export interface LogOutOptions {
  endOAuthSession?: boolean
}

export class ProjectArchived extends Error {
  constructor() {
    super("Project archived")
  }
}

export class InvalidToken extends Error {
  constructor() {
    super("Invalid token")
  }
}

const authenticationStore = new AuthenticationStore()
export default register(authenticationStore)